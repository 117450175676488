@import './base.scss';

@media screen and (max-width: 600px) {
    .login-wrapper {
        padding: 0 12px !important;

        .login-form {
            max-width: calc(100vw - 24px) !important;
            min-width: calc(100vw - 24px) !important;
        }
    }
}