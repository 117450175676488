@import './base.scss';

/** 
* General
*/
body {
    font-family: 'Poppins', sans-serif;
}

img {
    max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif;
}

.logo {
	width: 200px;
}

.logo-collapsed {
	width: 36px;
}

.app-circle-icon {
    cursor: pointer;
    width: 48px;
    height: 48px;
    background: $white-color;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 1px 1px 2px 0px rgb(0 0 0 / 40%); 
    box-shadow: 1px 1px 2px 0px rgb(0 0 0 / 40%);
}

.app-circle-icon--active {
    background-color: $primary-color;
    color: $white-color;
}

.filter-button {
    margin-right: 24px;
}

.search-input {
    border-radius: 8px !important;
    width: 400px;
}

// margin 
.mb-8 {
    margin-bottom: 8px;
}

.mb-16 {
    margin-bottom: 16px;
}

.mb-24 {
    margin-bottom: 24px;
}

.mb-36 {
    margin-bottom: 36px;
}

.mt-8 {
    margin-top: 8px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-24 {
    margin-top: 24px;
}

.mt-36 {
    margin-top: 36px;
}

.ml-8 {
	margin-left: 8px;
}

.ml-16 {
	margin-left: 16px;
}

// padding
.p-0 {
    padding: 0 !important;
}

// width
.w-100 {
    width: 100% !important;
}

// modal
.ant-modal-content {
    border-radius: 16px;
}

// form
.ant-input, .ant-select > .ant-select-selector {
    border-radius: 8px !important;
}

// toast
.toastContainer {
    z-index: 2000 !important;
}

// title
.page-title {
    .active, .inactive:hover {
        color: #11142D !important; 
    }

    .inactive {
        color: #6D6D6D !important; 
    }
}

// Links
.link {
	color: $primary-color;
	text-decoration: none;
	background-color: transparent;
	outline: none;
	cursor: pointer;
	transition: color 0.3s;
}

/** 
* Login page
*/
.login-wrapper {
    min-height: 100vh;
    align-items: center;
    padding: 0 60px;

    .login-form {
        max-width: 400px;
        min-width: 400px;
        margin: 60px auto;

        .login-form--input {
            border-radius: 8px;
            padding: 14px 16px;
        }

        .login-form--input:hover, .login-form--input:active, .login-form--input:focus, .ant-input-affix-wrapper-focused {
            border-color: $secondary-color !important;
            box-shadow: 0 0 0 2px $secondary-color-1 !important;
        }

        .login-form--button {
            height: 48px;
            width: 100%;
            border-radius: 8px;
            font-weight: 600;
            border-color: $secondary-color;
            background: $secondary-color;
        }

        .ant-alert {
            border-radius: 8px !important;
            margin: 24px 0px !important;
        }
    }
}

/** 
* Layout
*/

.app-sidebar {
	position: fixed;
	width: 250px !important;
	max-width: 250px !important;
	min-width: 250px !important;
	min-height: 100vh;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 40px 0px !important;
	border-right: solid 2px #e4e4e4;
    text-align: center;

	.sidebar-menu {
		margin-top: 12px;
		border-right: none !important;

		.ant-menu-item:not(.ant-menu-item-only-child),
		.ant-menu-submenu-title {
			height: 56px !important;
			line-height: 56px !important;
			padding: 18px 24px !important;
			// border-radius: 12px;
		}

		.ant-menu-item:not(.ant-menu-item-only-child):hover {
			color: $secondary-color !important;
		}

		.ant-menu-item:active {
			background-color: $white-color !important;
			color: $secondary-color !important;
		}

		.ant-menu-item.ant-menu-item-selected:hover {
			color: $white-color !important;
		}

		.ant-menu-item.ant-menu-item-selected::after {
			display: none !important;
		}

		// submenu
		.ant-menu-submenu-title:hover,
		.ant-menu-item-only-child:hover {
			color: $secondary-color !important;

			.ant-menu-submenu-arrow {
				color: $secondary-color !important;
			}
		}

		.ant-menu-sub {
			background-color: $white-color !important;
		}

		.ant-menu-submenu-selected {
			.ant-menu-submenu-title {
				background-color: $secondary-color !important;
				color: $white-color !important;
			}

			.ant-menu-submenu-arrow {
				color: $white-color !important;
			}

			.ant-menu-item-only-child.ant-menu-item-selected {
				background-color: $white-color !important;
				color: $secondary-color !important;
			}
		}
	}
}

.app-sidebar.ant-layout-sider-collapsed {
	width: 80px !important;
	max-width: 80px !important;
	min-width: 80px !important;

	.ant-menu-item:not(.ant-menu-item-only-child), .ant-menu-submenu-title {
		padding: 8px 24px !important;
	}
}

.menu-item--collapse.ant-menu-item-active {
	color: unset !important;
}

.app-sidebar::-webkit-scrollbar {
	display: none;
}

.main {
    margin-left: 250px;
    min-height: 100vh;
    transition: all .1s ease-out;
}

.main.main-expanded {
	margin-left: 80px !important;
}

// header
.app-header {
    background: #F9FAFC !important;
    padding: 12px 48px 0px;
    display: flex;
    align-items: center;
    justify-content: end; 
}

// content
.ant-layout-content {
    background: #F9FAFC;
    padding: 12px 24px !important;
}

/**
* Table
*/
.ant-table-content {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 20px;
    background: $white-color;
}

.ant-table-thead > tr > th {
    background: $primary-color-1;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.ant-table-thead > tr > th::before {
    display: none !important;
}

/**
* Loading
*/
.loader {
    margin-top: 48px;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.loader-logo {
	overflow: hidden;
	transform: translatey(0px);
	animation: float 3s ease-in-out infinite;
	
    img {
        width: 64px;
        height: 64px;
        object-fit: contain;
    }
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}

// upload
.ant-upload.ant-upload-drag {
	background-color: $primary-color-1 !important;
	border: 2px dashed $primary-color-2;
	border-radius: 8px;
	height: 80px;
	padding-left: 24px;
	padding-right: 24px;
}

.ant-upload.ant-upload-drag svg {
	margin-right: 16px;
	color: $primary-color-2;
}

.upload-area--success {
	display: flex;
	width: 100%;
	line-height: 44px;
	padding: 16px 24px;
	justify-content: space-between;
	align-items: center;
	background-color: $success-color-1;
	border: 2px dashed $success-color;
	border-radius: 8px;

	svg {
		margin-right: 16px;
		color: $success-color;
	}
}

.pointer {
    cursor: pointer;
}

.danger {
    color: $secondary-color;
}